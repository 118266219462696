/**
 * External dependencies.
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Layout from '@components/layout';
import SEO from "@components/seo";
import { getOgImage } from "@utils/functions";
import PostContent from '@components/post-content';
import PodcastsList from '@components/podcasts/podcasts-list/podcasts-list';
import Slider from "react-slick";
import './podcasts.scss';


const settings = {
	autoplay: false,
	arrows: false,
	dots: false,
	infinite: true,
	centerMode: true,
	variableWidth: true,

	speed: 500,
	// slidesToShow: 4,
	slidesToScroll: 2,
	// centerPadding: '40px',
	swipeToSlide: true,
	touchThreshold: 100,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				// slidesToScroll: 3,
				// slidesToShow: 3,
			}
		},
		{
			breakpoint: 767,
			settings: {
				centerMode: false,
				// slidesToScroll: 1,
				// slidesToShow: 1,
			}
		},
	]
};


/**
 * Single Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Page = (props) => {
	const guestsSliderRef = useRef();
	const hostsSliderRef = useRef();

	const { pageContext } = props;
	const {
		title,
		contentRaw,
		content,
		seo,
		uri,
		template,
		pageMeta,
		reusableBlocks,
		podcasts = [],
		podcastGuests
	} = pageContext;

	let theme = (pageMeta && pageMeta.theme) ? pageMeta.theme : '';
	const openGraphImage = getOgImage(seo);
	const { templateName } = template ?? '';

	const hosts = [];
	const guests = [];

	podcasts.map((podcast) => {
		if (podcast?.participants?.guests?.length > 0) {
			// podcast.participants.guests.map((guest) => {
			// 	if (!guests.some(({ id }) => id === guest.id)) {
			// 		guests.push(guest);
			// 	}
			// })
		}
		if (podcast?.participants?.hosts?.length > 0) {
			// podcast.participants.hosts.map((host) => {
			// 	if (!hosts.some(({ id }) => id === host.id)) {
			// 		hosts.push(host);
			// 	}
			// })
		}
	});

	const filters = [];

	if (podcastGuests && podcastGuests?.nodes?.length > 0) {

		podcasts.map(item => {
			if (item?.podcastCategories?.nodes?.length > 0) {
				const categories = item.podcastCategories.nodes;
				categories.map(category => {
					if (filters.indexOf(category.id) === -1) {
						filters.push(category.id);
					}
				})
			}
		});

		podcastGuests.nodes.map((guest) => {
			// const guestCategories = guest?.podcastCategories?.nodes;
			if (guest?.guestInfo?.showOnPodcastHomePage === 'Yes') {
				guests.push(guest);
			}
		});

	}


	useEffect(() => {
		if (document) {
			const podcastNavigation = [
				{
					href: "#about",
					text: 'About'
				},
				{
					href: "#episodes",
					text: 'Episodes'
				}
			]
			const newElement = document.createElement('div');
			newElement.classList.add('podcast-navigation');
			podcastNavigation.map(item => {
				const navitem = document.createElement('a');
				navitem.classList.add('podcast-navigation-item');
				navitem.textContent = item.text;
				navitem.href = item.href;

				newElement.append(navitem);
			})

			const header = document.querySelector('.site-header__container');
			// header[0].appendChild('hello')
			header.appendChild(newElement);

			console.log(header);
		}
	}, []);


	// console.log(pageContext)
	return (
		<Layout title={title} uri={uri} theme={theme} className="page podcasts-page" >
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<PostContent content={content} contentRaw={contentRaw} reusableBlocks={reusableBlocks} />

			<div class="guests-and-hosts-conteiner">
				{guests.length > 0 && <div className='persons-container guests'>
					<div className='persons-content'>

						<h2 className='persons-title'>Guests</h2>
						<div className='all-persons guests'>


							<Slider {...settings} ref={guestsSliderRef} >
								{guests.map((guest, key) => (<div className="the-person" key={key}>
									{guest?.featuredImage?.node?.sourceUrl && <img className="person-portrait" src={guest.featuredImage.node.sourceUrl} width={guest.featuredImage.node.mediaDetails.width} height={guest.featuredImage.node.mediaDetails.height} alt={guest.featuredImage.node.altText || guest.title} title={guest.featuredImage.node.altText || guest.title} />}
									<div className='person-info'>
										<span className='person-name'>{guest.title}</span>
										{guest?.guestInfo?.position && <span className='person-position'>{guest?.guestInfo?.position}</span>}
									</div>
								</div>))}
							</Slider>
						</div>
						<button className='people-slider-next-arrow' onClick={() => {
							if (guestsSliderRef) {
								guestsSliderRef.current.slickNext();
							}
						}}>
							<svg className="scroll-arrow" width="155" height="24" viewBox="0 0 155 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M138 23L154 12" stroke="black" strokeLinecap="square" />
								<path d="M138 0.999999L154 12" stroke="black" strokeLinecap="square" />
								<path d="M0.785714 12H153.214" stroke="black" strokeLinecap="square" />
							</svg>
						</button>
					</div>

				</div>}
				{/* {hosts.length > 0 && <div className='persons-container hosts'>
				<div className='persons-content'>
					<h2 className='persons-title'>Hosts</h2>
					<div className='all-persons '>
						<Slider {...settings} ref={hostsSliderRef} >
							{hosts.map((host, key) => (<div className="the-person" key={key} >
								{host?.featuredImage?.node?.sourceUrl && <img className="person-portrait" src={host.featuredImage.node.sourceUrl} width={host.featuredImage.node.mediaDetails.width} height={host.featuredImage.node.mediaDetails.height} alt={host.featuredImage.node.altText || host.title} title={host.featuredImage.node.altText || host.title} />}
								<div className='person-info'>
									<span className='person-name'>{host.title}</span>
									{host?.hostInfo?.position && <span className='person-position'>{host?.hostInfo?.position}</span>}
								</div>
							</div>))}
						</Slider>

					</div>
					<button className='people-slider-next-arrow' onClick={() => {
						if (hostsSliderRef) {
							hostsSliderRef.current.slickNext();
						}
					}}>
						<svg className="scroll-arrow" width="155" height="24" viewBox="0 0 155 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M138 23L154 12" stroke="black" strokeLinecap="square" />
							<path d="M138 0.999999L154 12" stroke="black" strokeLinecap="square" />
							<path d="M0.785714 12H153.214" stroke="black" strokeLinecap="square" />
						</svg>
					</button>
				</div>
			</div>} */}
			</div>
			<div className="listen">
				<div className='listen-content'>
					<div className="left-col">
						<span className="listen-title">Listen</span>
						<span className='listen-description'>Listen to the podcast that will change your life forever. Ask anyone. Go ahead - Ask.</span>
					</div>
					<div className="right-col">
						<a href="https://podcasts.apple.com/gb/podcast/the-community-exchange-podcast-by-openweb/id1698722253" className="app-store-button appstore" title="Listen on Apple">
							<AppleIcon /><span>Listen on Apple</span>
						</a>
						<a href="https://open.spotify.com/show/3ubAJ9XtaYvFv397iwsL5U?si=fd8c4d69ef8e44af" className="app-store-button googleplay" title="Listen on Spotify">
							<SpotifyIcon /><span>Listen on Spotify</span>
						</a>
					</div>
				</div>

			</div>
			{podcasts.length > 0 && <PodcastsList items={podcasts} size={3} showFilters />}
		</Layout>
	)
};

const SpotifyIcon = () => (<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M15.1828 0C6.79841 0 0 6.79731 0 15.1825C0 23.5678 6.79841 30.3657 15.1828 30.3657C23.5673 30.3657 30.3657 23.569 30.3657 15.1825C30.3657 6.79608 23.5685 0 15.1828 0ZM22.1464 21.8997C21.8734 22.3466 21.2909 22.4872 20.8452 22.2141C17.2793 20.0356 12.7924 19.5431 7.50728 20.7507C6.99812 20.8666 6.49082 20.5479 6.37431 20.0387C6.25781 19.5296 6.57588 19.0216 7.08627 18.9051C12.8694 17.5835 17.8309 18.1518 21.8327 20.5978C22.279 20.8697 22.4195 21.4534 22.1471 21.8997M24.0043 17.7647C23.661 18.322 22.9324 18.4964 22.3757 18.1543C18.2951 15.6455 12.0743 14.9193 7.24654 16.3858C6.62026 16.5744 5.95885 16.2218 5.769 15.5968C5.57976 14.9705 5.9342 14.3109 6.55862 14.1204C12.073 12.4468 18.9282 13.2574 23.6147 16.1374C24.1707 16.4801 24.3464 17.2087 24.0037 17.7647M24.164 13.4596C19.2696 10.5538 11.1971 10.2857 6.52534 11.7034C5.77578 11.9309 4.98122 11.5074 4.75438 10.7578C4.52754 10.007 4.9504 9.21493 5.70119 8.98623C11.0646 7.35826 19.9798 7.67263 25.6138 11.018C26.2887 11.418 26.51 12.2897 26.1106 12.9634C25.7105 13.6384 24.8383 13.8609 24.1646 13.4602" fill="white" />
</svg>
);



const AppleIcon = () => (<svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M29.4207 25.1093C28.6165 26.8909 28.2329 27.6889 27.1937 29.2602C25.7523 31.4562 23.7171 34.1967 21.1932 34.2214C18.9538 34.24 18.3785 32.7615 15.335 32.7801C12.2914 32.7986 11.6605 34.2462 9.41491 34.2276C6.891 34.2029 4.96094 31.7346 3.51959 29.5324C-0.51992 23.3834 -0.946759 16.1705 1.54623 12.3351C3.31545 9.60703 6.11155 8.01721 8.73445 8.01721C11.3573 8.01721 13.0894 9.48331 15.2979 9.48331C17.5063 9.48331 18.7497 8.01721 21.8427 8.01721C24.1811 8.01721 26.6555 9.29154 28.4185 11.4876C22.6407 14.6549 23.5748 22.9071 29.4145 25.1155L29.4207 25.1093Z" fill="white" />
	<path fillRule="evenodd" clipRule="evenodd" d="M19.4994 5.55509C20.6252 4.11374 21.4727 2.07852 21.1634 0C19.3262 0.129908 17.1858 1.29289 15.93 2.81466C14.7918 4.19416 13.8515 6.24175 14.2165 8.23366C16.2208 8.29552 18.2931 7.10161 19.4932 5.55509H19.4994Z" fill="white" />
</svg>
);

Page.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Page;
